import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/eco_01.jpg';
import pic2 from '../assets/images/eco_02.jpg';
import pic3 from '../assets/images/eco_03.jpg';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <p>
          Call us at <a href="tel:{config.phoneNumber}">{config.phoneNumber}</a>{' '}
          for a Free Estimate!
        </p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Eco Pestilence is proud to offer a full line of organic-compliant
            and non eco products to control pest infestations.
          </h2>
          <p>
            These treatments provide a quick knock down and long lasting
            protection for you property. Products range from Botanicals (plant)
            oils to Borates (mineral) salts effective against a broad spectrum
            of pests.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fa-home major style2">
              <span className="label">Residential</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-building major style2">
              <span className="label">Industrial</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-store-alt major style2">
              <span className="label">Commerical</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="Eco Pestilence" />
        </div>
        <div className="content">
          <h2>Family Owned &amp; Operated</h2>
          <p>
            With over 15 years of experience, this family owned and operated
            business has extensive training as route technicians and account
            managers. We saw a need to develop a company which focused on using
            alternate methods for pest control.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="Eco Pestilence Uniforms" />
        </div>
        <div className="content">
          <h2>
            Eco Friendly
            <br />
            Pest Control Options
          </h2>
          <p>
            Making safety the companies primary goal to ensure a low impact on
            our environment. Being proactive with Ecological Solutions for Pest
            Control and utilizing the latest innovations to ensure the highest
            standard for your environment.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="Eco Pestilence Trucks" />
        </div>
        <div className="content">
          <h2>
            Proudly Serving Brea, La Habra, Fullerton, and the greater Orange
            County area!
          </h2>
          <p>
            Eco Pestilence puts the safety of your Family and Pets first.
            Offering Ecological Solutions for Pest Management. Ask about our Eco
            Treatment.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Services Offered</h2>
          <p>
            Utilizing the latest innovations to keep your Home/ Property pest
            free is our goal.
            <br />
            Our technicians are highly trained in the following services
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-check-square">
            <h3>Bed Bug Extermination</h3>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Mosquito Abatement</h3>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Bee & Wasp Removal</h3>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Ant &amp; Cockroach Extermination</h3>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Rodent Extermination & Pest Proofing</h3>
          </li>
          <li className="icon solid fa-check-square">
            <h3>Termite Extermination & Preventative Services</h3>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Free Estimates</h2>
          <p>
            Call us at{' '}
            <a href="tel:{config.phoneNumber}">{config.phoneNumber}</a> for a
            Free Estimate!
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/contact" className="button fit primary">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
